import Swiper from 'swiper/bundle';

export const initProductsSlider = () => {
    // VARS
    const slider = document.querySelector('[data-swiper="products"]');
    if (!slider) return;

    // INITS
    const swiper = new Swiper('[data-swiper="products"]', {
        grabCursor: true,
        loop: true,
        resizeObserver: true,
        updateOnWindowResize: true,
        lazy: {
            loadPrevNext: true,
        },
        pagination: {
            el: '[data-swiper-pagination="products"]',
            clickable: true,
        },
        navigation: {
            nextEl: '[data-swiper-button-next="products"]',
            prevEl: '[data-swiper-button-prev="products"]',
        },
        breakpoints: {
            768: {
                spaceBetween: 40,
            },
        },
    });

    window.addEventListener('resize', function () {
        swiper.update();
    });
};
