import Swiper from 'swiper/bundle';

export const initReviewSlider = () => {
    // VARS
    const slider = document.querySelector('[data-swiper="review"]');
    if (!slider) return;

    // INITS
    const swiper = new Swiper('[data-swiper="review"]', {
        slidesPerView: 1.1,
        loop: true,
        grabCursor: true,
        spaceBetween: 12,
        navigation: {
            nextEl: '[data-swiper-button-next="review"]',
            prevEl: '[data-swiper-button-prev="review"]',
        },
        breakpoints: {
            768: {
                slidesPerView: 1.4,
            },
            1280: {
                slidesPerView: 2.4,
            },
        },
    });
};
