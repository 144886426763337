import anime from 'animejs';

export const initMobileMenu = () => {
    // VARS
    const hamburger = document.querySelector('[data-hamburger]');
    if (!hamburger) return;
    const switchers = document.querySelectorAll('[data-header-dropdown-switcher]');
    const nav = document.querySelector('[data-header-nav]');
    const anchorLinks = nav.querySelectorAll('[data-button-scroll-anchor]');
    const activeClass = 'mobile-menu-opened';
    const activeClassDropdown = 'is-open-dropdown';
    const duration = 300;

    // LISTENERS
    hamburger.addEventListener('click', handleOnClickHamburger, false);

    [...anchorLinks].forEach((anchorLink) => {
        anchorLink.addEventListener('click', handleOnClickAnchorLink, false);
    });

    [...switchers].forEach((switcher) => {
        switcher.addEventListener('click', handleOnClickSwitcher, false);
    });

    document.addEventListener('click', function (e) {
        if (!e.target.closest('[data-header-dropdown]') && window.innerWidth >= 1280) {
            closeAllDropdowns();
        }
    });

    window.addEventListener(
        'resize',
        function () {
            if (window.innerWidth >= 1280 && document.body.classList.contains('mobile-menu-opened')) {
                enableScroll();
            } else if (window.innerWidth < 1280 && document.body.classList.contains('mobile-menu-opened')) {
                disableScroll();
            }
        },
        false,
    );

    // HANDLERS
    function handleOnClickHamburger() {
        document.body.classList.toggle(activeClass);
        document.body.classList.contains(activeClass) ? window.disableScroll() : window.enableScroll();
    }
    function handleOnClickAnchorLink() {
        if (window.innerWidth <= 1280) {
            document.body.classList.toggle(activeClass);
            document.body.classList.contains(activeClass) ? window.disableScroll() : window.enableScroll();
        }
    }

    function handleOnClickSwitcher() {
        const dropdown = this.closest('[data-header-dropdown]');

        dropdown.classList.contains(activeClassDropdown) ? closeDropdown(dropdown) : openDropdown(dropdown);
    }

    // FUNCTIONS
    function openDropdown(dropdown) {
        const body = dropdown.querySelector('[data-header-dropdown-body]');
        const height = body.scrollHeight;
        dropdown.classList.add(activeClassDropdown);

        anime({
            targets: body,
            height: [0, height],
            easing: 'linear',
            duration: duration,
            complete: function () {
                const links = body.querySelectorAll('a');
                body.style.height = 'auto';

                [...links].forEach((link) => {
                    link.tabIndex = '0';
                });
            },
        });
    }

    function closeDropdown(dropdown) {
        const body = dropdown.querySelector('[data-header-dropdown-body]');
        const height = body.scrollHeight;
        body.style.height = `${height}px`;
        dropdown.classList.remove(activeClassDropdown);

        anime({
            targets: body,
            height: 0,
            easing: 'linear',
            duration: duration,
            complete: function () {
                const links = body.querySelectorAll('a');

                [...links].forEach((link) => {
                    link.tabIndex = '-1';
                });
            },
        });
    }

    function closeAllDropdowns() {
        const dropdowns = document.querySelectorAll('[data-header-dropdown]');

        [...dropdowns].forEach((dropdown) => {
            if (dropdown.classList.contains(activeClassDropdown)) {
                closeDropdown(dropdown);
            }
        });
    }
};
