export const initReviewCardAccordion = () => {
    // VARS
    // const btns = document.querySelectorAll('[data-review-card-toggle]');
    const cards = document.querySelectorAll('[data-review-card]');
    if (!cards.length) return;
    const activeClass = '--text-opened';

    // LISTENERS
    cards.forEach((card) => {
        card.addEventListener('click', handleOnclickCard);
    });

    // HANDLERS
    function handleOnclickCard() {
        const container = this.closest('[data-review-cards]');
        const card = this;
        const activeCard = container.querySelector(`.${activeClass}[data-review-card]`);

        if (activeCard && card !== activeCard) {
            activeCard.classList.remove(activeClass);
        }

        card.classList.toggle(activeClass);
    }
};
