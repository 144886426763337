import { initAccordion } from '../components/initAccordion';
import { initMobileMenu } from '../components/initMobileMenu';
import { initLanguageSwitcher } from '../components/initLanguageSwitcher';
import { initProductsSlider } from '../components/sliders/initProductsSlider';
import { initReviewSlider } from '../components/sliders/initReviewSlider';
import { initReviewCardAccordion } from '../components/initReviewCardAccordion';

document.addEventListener('DOMContentLoaded', handleOnLoadPage);

function handleOnLoadPage() {
    initAccordion();
    initMobileMenu();
    initLanguageSwitcher();
    initProductsSlider();
    initReviewSlider();
    initReviewCardAccordion();
}
