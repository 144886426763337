export const initLanguageSwitcher = () => {
    // VARS
    const langSwitchers = document.querySelectorAll('[data-language-switcher]');
    if (!langSwitchers.length) return;
    const activeClass = '--opened';
    const activeClassBody = '--mobile-menu-opened';
    const activeClassBodyMobileMenu = 'mobile-menu-opened';

    // LISTENERS
    langSwitchers.forEach((langSwitcher) => {
        langSwitcher.addEventListener('click', handleOnClick, false);
    });

    document.addEventListener('click', handleOutsideClick, false);

    // METHODS & FUNCTIONS
    function handleOnClick() {
        const langSwitcherWrap = this.closest('[data-language-switcher-wrap]');
        langSwitcherWrap.classList.toggle(activeClass);

        if (langSwitcherWrap.classList.contains(activeClass)) {
            document.body.classList.add(activeClassBody);
            document.body.classList.remove(activeClassBodyMobileMenu);
        } else {
            document.body.classList.remove(activeClassBody);
        }
    }

    function handleOutsideClick(event) {
        if (!event.target.closest('[data-language-switcher-wrap]')) {
            const langSwitcherWrap = document.querySelector(`.${activeClass}[data-language-switcher-wrap]`);
            if (langSwitcherWrap) {
                langSwitcherWrap.classList.remove(activeClass);
            }
        }
    }
};
